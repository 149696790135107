<template>
  <div>
    PersonalNavigationItem
  </div>
</template>

<script lang="js">
export default {
  name: 'PersonalNavigationItemModel',
}
</script>

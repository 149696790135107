<template>
  <v-card width="250">
    <v-card-title 
      v-if="title || subtitle" 
      style="font-size: 1rem;"
    >
      {{ title }} <br v-if="title">
      {{ subtitle }}
    </v-card-title>
    <v-card-text>
      <v-divider 
        v-if="title || subtitle" 
        class="mb-3"
      ></v-divider>
      <v-row 
        class="mb-1"
      >
        <v-subheader>Content</v-subheader>
        <v-col 
          cols="12"
          class="py-0"
        >
          <v-radio-group 
            v-model="value.permissions.CONTENT" 
            class="mt-0 mb-0"
          >
            <v-radio 
              v-for="(level, key) in restrictionLevels" 
              :key="key"
              :label="level.label"
              :value="level.value"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider 
        class="mb-3"
      >
      </v-divider>
      <v-row class="mb-1">
        <v-subheader>Comment</v-subheader>
        <v-col 
          cols="12" 
          class="py-0"
        >
          <v-radio-group
            v-model="value.permissions.COMMENT" 
            class="mt-0 mb-0"
          >
            <v-radio 
              v-for="(level, key) in restrictionLevels" 
              :key="key" 
              :label="level.label"
              :value="level.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider class="mb-3"></v-divider>
      <v-row class="mb-1">
        <v-subheader>Additional Constraints</v-subheader>
        <v-col 
          cols="12" 
          class="py-0"
        >
          <v-checkbox 
            v-model="value.constraints.restrictToOwnOrganization"
            class="mt-0" 
            label="Restrict contents to own organization" 
            hide-details
          >
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
export default {
  name: 'Restriction',
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      restrictionLevels: [
        { value: "READ_ONLY", label: 'Read-Only' },
        { value: "CREATE", label: 'Create' },
        { value: "MODERATE", label: 'Moderate' },
      ],
    };
  },
}
</script>

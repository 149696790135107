<template>
  <v-text-field
    :label="docs.name"
    :value="value"
    :disabled="readonly"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script lang="js">
export default {
  name:'String',
  props: {
    value: {
      type: String,
      default: null,
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

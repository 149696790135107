<template>
  <div>
    <v-divider></v-divider>
    <v-subheader class="pl-0 accent--text text-uppercase">
      {{ docs.name }}
    </v-subheader>

    <v-row
      v-if="localValue && docs"
      dense
    >
      <v-col
        v-for="(subField, fieldKey) in fields"
        :key="subField.name + fieldKey + 'qwe32'"
        cols="6"
      >
        <component
          :is="getComponentName(subField)"
          v-model="localValue[subField.name]"
          :docs="subField"
          :name="`${subField.type}-${subField.name}-${fieldKey}-123üj1iop2323`"
          :readonly="readonly"
          @input="$emit('input', localValue)"
        ></component>
      </v-col>
    </v-row>
    <v-divider class="mb-3"></v-divider>
  </div>
</template>

<script >
import { getComponentName } from "@/modules/navigation/components/utility/utility"
import NavigationTabType from "@/modules/navigation/components/navigationFields/NavigationTabType"
import SetNavigationTabType from "@/modules/navigation/components/navigationFields/SetNavigationTabType"
export default {
  name: 'NavigationItemViewModel',
  components: {
    NavigationTabType,
    SetNavigationTabType,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  computed: {
    fields() {
      return (this.docs && this.localValue) ? this.docs.subType.fields : []
    },
  },
  mounted() {
    this.localValue = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    getComponentName,
  },
}
</script>

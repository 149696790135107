<template>
  <v-autocomplete
    :value="activeAppInstance"
    :items="appInstances"
    outlined
    label="App instance"
    dense
    filled
    autofocus
    return-object
    prepend-inner-icon="landscape"
    item-value="id"
    :filter="customFilter"
    single-line
    hide-details
    @input="selectAppInstance($event)"
  >
    <template v-slot:selection="data">
      {{ data.item.description || data.item.environmentId }}
    </template>
    <template v-slot:item="data">
      {{ data.item.description }} | {{ data.item.environmentId }}
    </template>
  </v-autocomplete>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  name: 'AppInstanceSelect',
  computed: {
    ...mapGetters({
      activeAppInstance: 'getSelectedMercuryAppInstance',
      appInstances: 'getMercuryAppInstances',
    }),
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return (item.description + item.environmentId).toUpperCase().includes(queryText.toUpperCase())
    },
    selectAppInstance(e) {
      this.$emit('input', e)
      this.$store.commit('SELECT_MERCURY_APP_INSTANCE', e.id)
    },
  },
}
</script>

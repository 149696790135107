<template>
  <div>
    <toolbar-floating toolbar-id="navigation">
      <app-instance-select @input="getNavigation"></app-instance-select>
      <v-divider vertical></v-divider>
      <button-add
        v-if="!navigation"
        label="Add navigation"
        @click="addNavigation"
      >
      </button-add>
      <button-primary
        v-if="navigation"
        @click="save()"
      ></button-primary>
    </toolbar-floating>

    <v-row v-if="activeAppInstance && navigation">
      <SelectorCards
        v-model="activeNavigationGroupId"
        :items="navigation.navigationGroups"
        item-text="label"
        name="navigation group"
        nested-property="dto"
        @add="addGroup"
      ></SelectorCards>

      <v-col
        v-if="activeNavigationGroupId !== null"
        cols="6"
        md="5"
        sm="6"
        xl="4"
      >
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col class="grow">
                <v-text-field
                  v-model="navigation.navigationGroups[activeNavigationGroupId].dto.label"
                  :readonly="isTemplate"
                  append-icon="edit"
                  class="display-1 shrink"
                  hide-details
                  outlined
                ></v-text-field>
                <v-subheader
                  v-if="isTemplate"
                  style="color:red"
                >
                  This is a template and therefore READ-ONLY
                </v-subheader>
              </v-col>
              <v-col class="shrink">
                <button-confirm
                  v-if="!isTemplate"
                  :right="false"
                  :top="false"
                  fab
                  icon="delete"
                  position="relative"
                  @confirmed="removeGroup(activeNavigationGroupId)"
                ></button-confirm>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  elevation="0"
                  fab
                  small
                  @click="jsonEditor = true"
                >
                  <v-icon color="green">
                    code
                  </v-icon>
                </v-btn>
                <v-dialog
                  v-if="!!jsonEditor"
                  v-model="jsonEditor"
                  max-width="1000"
                >
                  <json-editor
                    v-model="navigation.navigationGroups[activeNavigationGroupId]"
                    :modes="isTemplate ? ['view'] : ['code']"
                  ></json-editor>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>

        <v-expansion-panels v-model="activeNavigationItemId">
          <draggable
            :list="navigation.navigationGroups[activeNavigationGroupId].dto.navigationItems"
            handle=".v-expansion-panel-header"
            style="width: 100%"
            @end="dragEnd"
          >
            <v-expansion-panel
              v-for="(navigationItem, itemKey) in activeNavigationGroup.navigationItems"
              :key="`${itemKey}`"
            >
              <v-expansion-panel-header ripple>
                <v-row align="center">
                  <v-col class="shrink py-0">
                    <span style="white-space: nowrap">
                      {{ navigationItem.label || 'New item' }}
                    </span>
                  </v-col>
                  <v-col
                    v-if="activeNavigationItemId === itemKey"
                    class="shrink py-0"
                  >
                    <button-confirm
                      v-if="!isTemplate"
                      :right="false"
                      :top="false"
                      fab
                      position="relative"
                      @confirmed="removeItem(itemKey)"
                    ></button-confirm>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <navigation-item-model
                  v-if="activeNavigationItem"
                  v-model="navigation.navigationGroups[activeNavigationGroupId].dto.navigationItems[itemKey]"
                  :docs="navigationInfo.find(el => el._class.split('.').slice(-1)[0] === typeMap[navigationItem.type])"
                  :readonly="isTemplate"
                ></navigation-item-model>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>

        <v-row
          v-if="!isTemplate"
          align="center"
          class="mt-2"
          dense
        >
          <v-spacer></v-spacer>
          <v-col class="shrink">
            Add
          </v-col>
          <v-col class="shrink">
            <v-select
              v-model="newItemType"
              :items="Object.keys(typeMap)"
              class="d-inline-block"
              dense
              hide-details
              outlined
              style="width: 150px"
            ></v-select>
          </v-col>
          <v-col class="shrink">
            <button-add
              :text="false"
              class="mt-3"
              @click="addItem"
            ></button-add>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="activeNavigationGroup && activeNavigationItem && activeNavigationItem.categories">
        <v-subheader>
          PERMISSIONS

          <button-add @click="showRestrictionDialog = true"></button-add>

          <button-primary
            v-if="navigationItemRestrictions.length"
            class="ml-2"
            small
            @click="saveRestrictions()"
          ></button-primary>
        </v-subheader>
        <v-row>
          <v-col
            v-for="(restriction, key) in navigationItemRestrictions"
            :key="key"
            class="shrink"
          >
            <restriction
              v-model="navigationItemRestrictions[key]"
              :subtitle="restriction.groupId"
              :title="getCategoryName(restriction.categoryId)"
              class="mb-2"
            ></restriction>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog
        v-if="activeNavigationGroup && activeNavigationItem"
        v-model="showRestrictionDialog"
        max-width="600"
      >
        <v-card>
          <v-card-title>New permission</v-card-title>
          <v-card-text>
            <v-select
              v-model="newRestriction.categoryId"
              :items="activeNavigationItem.categories"
              label="Category"
            ></v-select>
            <template v-if="restrictionGroups.length > 0">
              <v-combobox
                v-model="newRestriction.groupId"
                :items="restrictionGroups"
                item-text="name"
                item-value="value"
                label="Group"
              ></v-combobox>
              <restriction v-model="newRestriction"></restriction>
            </template>
            <span v-if="restrictionGroups.length === 0 && newRestriction.categoryId">No valid group/category combination
              available.</span>
          </v-card-text>
          <v-card-actions v-if="restrictionGroups.length > 0">
            <v-spacer></v-spacer>
            <button-primary
              label="Add permission"
              @click="addRestriction()"
            ></button-primary>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import PersonalNavigationItemModel from "@/modules/navigation/components/PersonalNavigationItemModel"
import NavigationItemModel from "@/modules/navigation/components/NavigationItemModel"
import ToolbarFloating from "@/shared/components/layout/ToolbarFloating"
import ButtonAdd from "@/shared/components/layout/ButtonAdd"
import ButtonConfirm from "@/shared/components/layout/ButtonConfirm"
import {mapActions, mapGetters} from 'vuex'
import ButtonPrimary from "@/modules/categories/ButtonPrimary"
import AppInstanceSelect from "@/modules/navigation/AppInstanceSelect"
import SelectorCards from "@/modules/navigation/SelectorCards"
import Restriction from "@/modules/restrictions/components/Restriction"
import JsonEditor from "@/shared/components/JsonEditor"


export default {
  components: {
    JsonEditor,
    Restriction,
    SelectorCards,
    AppInstanceSelect,
    ButtonPrimary,
    ButtonAdd,
    ToolbarFloating,
    draggable,
    ButtonConfirm,
    NavigationItemModel,
  },
  data() {
    return {
      jsonEditor: false,
      typeMap: {
        'FILTER': 'NavigationItemModel',
        'PERSONAL': 'PersonalNavigationItemModel',
      },
      activeNavigationGroupId: null,
      activeNavigationItemId: null,
      navigationInfo: null,
      navigation: null,
      newItemType: 'FILTER',
      restrictions: null,
      categories: null,
      showRestrictionDialog: false,
      navigationItemRestrictions: null,
      newRestriction: {
        categoryId: null,
        groupId: null,
        permissions: {},
        constraints: {
          restrictToOwnOrganization: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      activeAppInstance: 'getSelectedMercuryAppInstance',
      appInstances: 'getMercuryAppInstances',
    }),
    activeNavigationGroup: {
      get() {
        if (this.activeNavigationGroupId !== null && this.navigation.navigationGroups[this.activeNavigationGroupId]) {
          return this.navigation.navigationGroups[this.activeNavigationGroupId].dto
        } else {
          return null;
        }
      },
      set(group) {
        this.navigation.navigationGroups[this.activeNavigationGroupId].dto = group
      },
    },
    activeNavigationItem: {
      get() {
        return this.navigation.navigationGroups[this.activeNavigationGroupId].dto.navigationItems[this.activeNavigationItemId] || null
      },
      set(item) {
        this.navigation.navigationGroups[this.activeNavigationGroupId].dto.navigationItems[this.activeNavigationItemId] = item
      },
    },
    usedCategories() {
      if (this.activeNavigationItemId != null && this.activeNavigationItem.categories) {
        return this.activeNavigationItem.categories.join('-')
      } else {
        return null
      }
    },
    usedGroups() {
      return this.navigationItemRestrictions.reduce((sum, current) => {
        if (!sum[current.categoryId]) {
          sum[current.categoryId] = []
        }

        sum[current.categoryId].push(current.groupId)

        return sum
      }, {})
    },
    restrictionGroups() {
      if (this.newRestriction.categoryId) {
        return this.restrictions.reduce((sum, current) => {
          if (
            !sum.includes(current.groupId)
            && (
              !this.usedGroups[this.newRestriction.categoryId]
              || !this.usedGroups[this.newRestriction.categoryId].includes(current.groupId)
            )
          ) {
            sum.push(current.groupId)
          }
          return sum
        }, [])
        //     .map(el => {
        //   return {name: el.split('_').slice(-1)[0], value: el}
        // })
      } else {
        return []
      }
    },
    isTemplate() {
      return !!this.navigation.navigationGroups[this.activeNavigationGroupId].templateId
    },
  },
  watch: {
    activeNavigationGroupId(n, o) {
      if (n !== o) {
        this.activeNavigationItemId = null
      }
    },
    activeNavigationItemId(n) {

      if (n != null && this.activeNavigationItem.type === 'FILTER') {
        this.newRestriction = {
          categoryId: null,
          groupId: null,
          permissions: {},
          constraints: {
            restrictToOwnOrganization: false,
          },
        }

        this.navigationItemRestrictions = this.restrictions.filter(el => {
          return this.activeNavigationItem.categories.includes(el.categoryId)
        })
      }

    },
    usedCategories() {
      if (this.activeNavigationItemId != null && this.activeNavigationItem.categories) {
        this.navigationItemRestrictions = this.restrictions.filter(el => {
          return this.activeNavigationItem.categories.includes(el.categoryId)
        })
      }
    },
  },
  mounted() {
    this.getNavigation(this.activeAppInstance)
    this.fetchNavigationsInfo().then(res => {
      this.navigationInfo = res
    })
    this.fetchCategories().then(res => {
      this.categories = res.categories
    })
  },
  methods: {
    ...mapActions([
      'fetchNavigations',
      'fetchNavigationsInfo',
      'updateNavigation',
      'createNavigation',
      'fetchCategories',
      'fetchRestrictions',
      'upsertRestrictions',
    ]),
    getNavigation(appInstance) {
      if (appInstance.id !== this.activeAppInstance.id) {
        this.activeNavigationGroupId = null
      }
      this.fetchNavigations(appInstance.environmentId).then(res => {
        this.navigation = res
      })
      this.fetchRestrictions(appInstance.environmentId).then(res => {
        this.restrictions = res
      })
    },
    dragEnd(e) {
      if (this.activeNavigationItemId === e.oldIndex) {
        this.activeNavigationItemId = e.newIndex
      } else if (this.activeNavigationItemId > e.oldIndex && this.activeNavigationItemId <= e.newIndex) {
        this.activeNavigationItemId = this.activeNavigationItemId - 1
      } else if (this.activeNavigationItemId < e.oldIndex && this.activeNavigationItemId >= e.newIndex) {
        this.activeNavigationItemId = this.activeNavigationItemId + 1
      }
    },
    async save() {
      let result
      if (this.navigation.id) {
        result = await this.updateNavigation(this.navigation)
      } else {
        result = await this.createNavigation(this.navigation)
      }
      if (result) {
        this.getNavigation(this.activeAppInstance)
      }
    },
    async saveRestrictions() {
      await this.upsertRestrictions(this.navigationItemRestrictions)
    },
    addItem() {
      let definition = this.navigationInfo.find(el => el._class.split('.').slice(-1)[0] === this.typeMap[this.newItemType])

      this.$set(this.navigation.navigationGroups[this.activeNavigationGroupId].dto, 'navigationItems', [
        ...this.activeNavigationGroup.navigationItems,
        definition.fields.reduce((sum, current) => {
          sum[current.name] = this.parseDefinition(current)
          return sum
        }, {type: this.newItemType}),
      ])


      this.activeNavigationItemId = this.activeNavigationGroup.navigationItems.length - 1

      if (this.newItemType === 'FILTER') {
        this.navigation.navigationGroups[this.activeNavigationGroupId].dto.navigationItems[this.activeNavigationItemId].view = {
          default: 'ALL',
          available: ['ALL', 'MUST_READ', 'UNREAD'],
        }
        this.navigation.navigationGroups[this.activeNavigationGroupId].dto.navigationItems[this.activeNavigationItemId].orders = ['CREATED_DESCENDING']
      }

    },
    removeItem(key) {
      this.activeNavigationItemId = null
      this.navigation.navigationGroups[this.activeNavigationGroupId].dto.navigationItems.splice(key, 1)
    },
    addGroup() {
      this.navigation.navigationGroups.push({
        dto: {label: 'New group', navigationItems: []},
        templateId: null,
      })
    },
    removeGroup(key) {
      this.activeNavigationGroupId = null
      this.navigation.navigationGroups.splice(key, 1)
    },
    addNavigation() {
      this.navigation = {
        environmentId: this.activeAppInstance.environmentId,
        navigationGroups: [],
      }
    },
    addRestriction() {
      this.navigationItemRestrictions.push(this.newRestriction)
      this.showRestrictionDialog = false
      this.newRestriction = {
        categoryId: null,
        groupId: null,
        permissions: {},
        constraints: {
          restrictToOwnOrganization: false,
        },
      }
    },
    parseDefinition(itemDefinition) {
      if (itemDefinition.type.includes('Set<')) {
        return []
      } else if (itemDefinition.subType) {
        return itemDefinition.subType.fields.reduce((sum, current) => {
          sum[current.name] = this.parseDefinition(current)
          return sum
        }, {})
      } else {
        return null
      }
    },
    getCategoryName(id) {
      let category = this.categories.find(cat => {
        return cat.id === id
      })
      if (!category) {
        return id
      }
      return category.name || category.id
    },
  },

}
</script>

<style>
.v-expansion-panel-header.v-expansion-panel-header--active {
  color: var(--v-accent-base);
}
</style>

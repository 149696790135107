<template>
  <v-combobox
    v-if="localValue"
    v-model="localValue"
    :label="docs.name"
    :items="value"
    :disabled="readonly"
    multiple
    chips
    deletable-chips
    small-chips
    @input="emitInput()"
  ></v-combobox>
</template>

<script lang="js">
export default {
  name: 'SetString',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  mounted() {
    this.localValue = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    emitInput() {
      this.$emit('input',this.localValue)
    },
  },
}
</script>

<template>
  <v-select
    :label="docs.name"
    :value="value"
    :items="docs.enumValues"
    item-text="label"
    item-value="id"
    :disabled="readonly"
    @change="$emit('input', $event)"
  ></v-select>
</template>

<script lang="js">
export default {
  name: 'NavigationTabType',
  props: {
    value: {
      type: String,
      default: null,
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

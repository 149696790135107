const customTypes = ['categories', 'defaultCategory']

function getComponentName(field) {
  if (customTypes.includes(field.name)) {
    return field.name
  } else {
    return field.type
      .split('<').join('')
      .split('>').join('')
  }
}

export { getComponentName }

<template>
  <v-combobox
    v-if="localValue"
    v-model="localValue"
    label="Categories"
    :items="[...categories, ...value]"
    item-value="id"
    item-text="name"
    :disabled="readonly"
    multiple
    chips
    deletable-chips
    small-chips
    @input="emitInput()"
  ></v-combobox>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
  name: 'Categories',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'getCategories',
    }),
  },
  mounted() {
    this.localValue = JSON.parse(JSON.stringify(this.value.map(el => {
      return this.categories.find(category => category.id === el) || {id: el, name: el}
    })))
  },
  methods: {
    emitInput() {
      this.$emit('input', this.localValue.map(el => el.id || el))
    },
  },
}
</script>

<template>
  <v-row
    dense
    align="center"
    class="mt-2"
  >
    <v-spacer></v-spacer>
    <v-col
      class="shrink"
      style="white-space: nowrap"
    >
      Add {{ name }}
    </v-col>
    <v-col class="shrink">
      <button-add
        class="mt-3"
        :text="false"
        @click="$emit('add')"
      ></button-add>
    </v-col>
  </v-row>
</template>
<script>
import ButtonAdd from "@/shared/components/layout/ButtonAdd"

export default {
  name: 'ItemAdder',
  components: {ButtonAdd},
  props: {
    name: {},
  },
}
</script>

<template>
  <v-col
    v-if="items"

    cols="6"
    sm="5"
    md="4"
    lg="3"
    xl="2"
  >
    <v-subheader>{{ namePlural ? namePlural.toUpperCase() : name.toUpperCase() + 'S' }}</v-subheader>
    <SelectorCard
      v-for="(item, itemKey) in realItems()"
      :key="itemKey"
      :title="itemText ? item[itemText] : item"
      :active="itemKey === value"
      @click.native="$emit('input', itemKey)"
    >
    </SelectorCard>
    <slot
      v-if="footer"
      name="footer"
    >
      <ItemAdder
        :name="name"
        @add="$emit('add')"
      />
    </slot>
  </v-col>
</template>
<script>
import SelectorCard from "@/shared/components/layout/SelectorCard.vue"
import ItemAdder from "@/modules/navigation/ItemAdder"

export default {
  name: 'SelectorCards',
  components: {ItemAdder, SelectorCard},
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: null,
    },
    itemText: {
      type: String,
      required: false,
      default: null,
    },
    nestedProperty: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      default: null,
      type: String,
      required: false,
    },
    namePlural: {
      default: null,
      type: String,
      required: false,
    },
    footer: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    realItems(){
      if(this.nestedProperty){
        return this.items.map(item => item[this.nestedProperty]);
      } else {
        return this.items;
      }
    },
  },
}
</script>

<template>
  <v-row
    v-if="localValue && docs"
    dense
  >
    <v-col
      v-for="(field, fieldKey) in fields"
      :key="field.name + fieldKey"
      :cols="field.type === 'NavigationItemViewModel' ? 12 : 6 "
    >
      <component
        :is="getComponentName(field)"
        v-model="localValue[field.name]"
        :docs="field"
        :name="`${field.type}-${field.name}-${fieldKey}-123123`"
        :readonly="readonly"
        @input="$emit('input', localValue)"
      ></component>
    </v-col>
  </v-row>
</template>

<script lang="js">
import NavigationFields from "@/modules/navigation/components/navigationFields/NavigationFields"
import { getComponentName } from "@/modules/navigation/components/utility/utility"

export default {
  name: 'NavigationItemModel',
  components: {
    ...NavigationFields,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  computed: {
    fields() {
      return (this.docs && this.localValue) ? this.docs.fields : null
    },
    id() {
      return this.value.id
    },
  },
  watch: {
    id() {
      this.setLocalValue()
    },
  },
  created() {
    this.setLocalValue()
  },
  methods: {
    getComponentName,
    setLocalValue() {
      this.localValue = JSON.parse(JSON.stringify(this.value))
    },
  },
}
</script>

<template>
  <v-autocomplete
    v-model="localValue"
    label="Default category"
    :items="items"
    item-value="id"
    item-text="name"
    :disabled="readonly"
    @input="$emit('input', localValue)"
  ></v-autocomplete>
</template>

<script lang="js">
import {mapGetters} from 'vuex'

export default {
  name: 'DefaultCategory',
  props: {
    value: {
      type: String,
      default: null,
    },
    docs: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: null,
    }
  },
  computed: {
    ...mapGetters({
      categories: 'getCategories',
    }),
    items() {
      if (this.localValue) {
        return [...this.categories, this.localValue]
      }
      return this.categories
    },
  },
  mounted() {
    this.localValue = this.value
  },
}
</script>

<template>
  <vue-json-editor
    :value="value"
    :mode="modes[0]"
    :modes="modes"
    @input="$emit('input', $event)"
  ></vue-json-editor>
</template>
<script>
import VueJsonEditor from 'vue-json-editor'

export default {
  name: 'JsonEditor',
  components: {VueJsonEditor},
  props: {
    value: {},
    modes:{
      default: ()=>{['code']},
      type: Array,
      required: false,
    },
  },
}
</script>

<style lang="scss">

.jsoneditor-poweredBy {
  display: none !important;
}

.jsoneditor-vue {
  color: #aaa;
  height: 800px;

  .jsoneditor {
    border: none;
  }

  .jsoneditor-tree {
    background-color: #222;
    color: #555;
    .jsoneditor-field {
      color: #eee;
    }
  }
  .ace-jsoneditor {

    .ace_gutter {
      background-color: #222;
      color: #555;

      &-active-line {
        background-color: #413f35;
      }
    }

    .ace_scroller {
      background-color: #222;
    }

    .ace_variable {
      color: #ffc4a4;
    }

    .ace_text-layer {
      color: #eee;

      .ace_indent-guide {
        opacity: 20%;
      }
    }

    .ace_constant {
      &.ace_numeric {
        color: #00afff;
      }
    }

    .ace_marker-layer {
      .ace_active-line {
        background-color: #413f35;
      }

      .ace_selection {
        background-color: #413f35;
      }
    }

    .ace_cursor-layer {
      border-left-color: #fff;
      color: #fff;

      .ace_cursor {
        color: #fff;
        border-left-color: #fff;
      }
    }
  }
}
</style>

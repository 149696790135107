<template>
  <div>
    <toolbar-floating toolbar-id="restrictions">
      <app-instance-select @input="getRestrictions"></app-instance-select>
      <v-divider vertical></v-divider>
      <button-primary
        v-if="activeGroupIndex !== null" 
        label="Save" 
        @click="save()"
      ></button-primary>
    </toolbar-floating>
    <v-row>
      <SelectorCards 
        v-if="groups" 
        v-model="activeGroupIndex" 
        :items="groups" 
        item-text="groupName" 
        name="group"
      >
        <template v-slot:footer>
          Groups can be added in
          <router-link to="/app-instances">
            App instances
          </router-link>
        </template>
      </SelectorCards>
      <SelectorCards 
        v-if="restrictions && activeGroupIndex !== null" 
        v-model="activeRestrictionIndex"
        :items="groupRestrictions"
        name="Category"
        name-plural="Categories"
        item-text="name"
      >
        <template v-slot:footer>
          <v-row align="center">
            <v-col>
              <v-autocomplete 
                v-model="newRestriction.categoryId"
                :items="availableCategories"
                item-value="id"
                item-text="name" 
                solo 
                label="Add category" 
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col class="shrink pl-0">
              <ButtonAdd 
                :text="false"
                @click="addRestriction"
              ></ButtonAdd>
            </v-col>
          </v-row>
        </template>
      </SelectorCards>
      <v-col 
        v-if="activeRestriction" 
        class="shrink"
      >
        <v-subheader>RESTRICTIONS</v-subheader>
        <Restriction 
          v-if="activeRestriction" 
          v-model="activeRestriction"
        ></Restriction>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ToolbarFloating from "@/shared/components/layout/ToolbarFloating"
import { mapActions, mapGetters } from 'vuex'
import AppInstanceSelect from "@/modules/navigation/AppInstanceSelect"
import SelectorCards from "@/modules/navigation/SelectorCards"
import Restriction from "@/modules/restrictions/components/Restriction"
import ButtonPrimary from "@/modules/categories/ButtonPrimary"
import ButtonAdd from "@/shared/components/layout/ButtonAdd"

export default {
  name: 'Restrictions',
  components: {
    ButtonAdd,
    ButtonPrimary,
    Restriction,
    SelectorCards,
    AppInstanceSelect,
    ToolbarFloating,
  },
  data() {
    return {
      activeRestrictionIndex: null,
      restrictions: {},
      activeGroupIndex: null,
      groups: null,
      newRestriction: {
        categoryId: null,
        groupId: null,
        permissions: {},
        constraints: {
          restrictToOwnOrganization: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      activeAppInstance: 'getSelectedMercuryAppInstance',
      categories: 'getCategories',
    }),
    activeRestriction() {
      if (this.groupRestrictions && this.activeRestrictionIndex !== null) {
        return this.groupRestrictions[this.activeRestrictionIndex]
      } else {
        return null
      }
    },
    groupRestrictions() {
      if (this.activeGroupIndex === null) {
        return null
      }
      return this.enrichedRestrictions.filter(el => el.groupId === this.groups[this.activeGroupIndex].id)
    },
    enrichedRestrictions() {
      return this.restrictions.map(restriction => {
        let cat = this.categories.find(category => {
          return category.id === restriction.categoryId
        })
        restriction.name = cat ? cat.name : restriction.categoryId
        return restriction
      })
    },
    availableCategories() {
      let usedCategories = this.groupRestrictions.map(el => el.categoryId)
      return this.categories.filter(el => !usedCategories.includes(el.id))
    },
  },
  watch: {
    activeGroupIndex() {
      this.activeRestrictionIndex = null
    },
  },
  created() {
    this.fetchMercuryAppInstances()
  },
  async mounted() {
    await this.fetchCategories()
    await this.getRestrictions(this.activeAppInstance)
  },
  methods: {
    ...mapActions([
      'fetchRestrictions',
      'fetchCategories',
      'updateRestriction',
      'fetchMercuryAppInstanceGroups',
      'fetchMercuryAppInstances',
      'upsertRestrictions',
    ]),
    addRestriction() {
      this.restrictions.push({ ...this.newRestriction, groupId: this.groups[this.activeGroupIndex].id })
      this.newRestriction.categoryId = null
      this.newRestriction.groupId = null
      this.addRestrictionDialog = false
      this.activeRestrictionIndex = this.groupRestrictions.length - 1
    },
    async getRestrictions(e) {
      this.activeRestrictionIndex = null
      this.activeGroupIndex = null
      this.restrictions = await this.fetchRestrictions(e.environmentId)
      this.groups = await this.fetchMercuryAppInstanceGroups(e)
    },
    async save() {
      await this.upsertRestrictions(this.groupRestrictions)
    },
  },
}
</script>

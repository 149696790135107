import String from "@/modules/navigation/components/navigationFields/String"
import SetString from "@/modules/navigation/components/navigationFields/SetString"
import SetOrderType from "@/modules/navigation/components/navigationFields/SetOrderType"
import NavigationItemViewModel from "@/modules/navigation/components/navigationFields/NavigationItemViewModel"
import NavigationTabType from "@/modules/navigation/components/navigationFields/NavigationTabType"
import SetNavigationTabType from "@/modules/navigation/components/navigationFields/SetNavigationTabType"
import NavigationItemType from "@/modules/navigation/components/navigationFields/NavigationItemType"
import Categories from "@/modules/navigation/components/navigationFields/Categories"
import DefaultCategory from "@/modules/navigation/components/navigationFields/DefaultCategory"


export default {
  String,
  SetString,
  SetOrderType,
  NavigationItemViewModel,
  NavigationTabType,
  SetNavigationTabType,
  NavigationItemType,
  Categories,
  DefaultCategory,
}
